import {createApi} from '@reduxjs/toolkit/query/react';
import {embeddedFile} from "@/services/models/File";
import {uploadBaseQuery} from "@/store/features/uploadBaseQuery";

export const projectFiles = createApi({
    reducerPath: 'projectFilesApi',
    baseQuery: uploadBaseQuery,
    tagTypes: ['ProjectFiles'],
    endpoints: (builder) => ({
        getProjectFiles: builder.query<embeddedFile[], string>({
            query: (uid) => ({
                url: `/File/${uid}`,
                method: 'GET'
            }),
            providesTags: (result, error, uid) => [{type: 'ProjectFiles', id: uid}]
        }),
        uploadFile: builder.mutation<embeddedFile, {
            file: File;
            uid: string;
            onProgress?: (progress: number) => void
        }>({
            query: ({file, uid, onProgress}) => ({
                url: `/File/single/${uid}`,
                method: 'POST',
                file,
                onProgress
            }),
            invalidatesTags: (result, error, {uid}) => [{type: 'ProjectFiles', id: uid}]
        }),
        deleteChatFile: builder.mutation<void, { uid: string; fileId: string }>({
            query: ({uid, fileId}) => ({
                url: `/File/${uid}/${fileId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, {uid}) => [{type: 'ProjectFiles', id: uid}]
        }),
    }),
});

const {
    useGetProjectFilesQuery,
    useUploadFileMutation,
    useDeleteChatFileMutation
} = projectFiles;
export const projectFilesApiActions = {
    useGetProjectFilesQuery,
    useUploadFileMutation,
    useDeleteChatFileMutation
}