import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface PendingMessageState {
    message: string;
    agent: string;
    chatId: string;
    isPending: boolean;
}

const initialState: PendingMessageState = {
    message: '',
    agent: '',
    chatId: '',
    isPending: false
};

export const pendingMessageSlice = createSlice({
    name: "pendingMessage",
    initialState,
    reducers: (create) => ({
        setPendingMessage: create.reducer(
            (state, action: PayloadAction<{ message: string; agent: string; chatId: string }>) => {
                state.message = action.payload.message;
                state.agent = action.payload.agent;
                state.chatId = action.payload.chatId;
                state.isPending = true;
            }
        ),
        clearPendingMessage: create.reducer((state) => {
            state.message = '';
            state.agent = '';
            state.chatId = '';
            state.isPending = false;
        }),
    })
});

export const pendingMessageSliceActions = pendingMessageSlice.actions;
export default pendingMessageSlice;