import {createListenerMiddleware, createSlice, isAnyOf, PayloadAction} from '@reduxjs/toolkit';
import {ProjectTheme} from '@/services/models/project-theme';
import {themeApiSlice} from './themeApiSlice';
import {AppDispatch, RootState} from "@/store/store";

interface ThemeState {
    currentTheme?: ProjectTheme;
}

const initialState: ThemeState = {
    currentTheme: undefined,
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setCurrentTheme: (state, action: PayloadAction<ProjectTheme>) => {
            state.currentTheme = action.payload;
        },
        clearCurrentTheme: (state) => {
            state.currentTheme = undefined;
        },
    }
});
export const themeSliceActions = themeSlice.actions;

export default themeSlice.reducer;