import {createApi} from "@reduxjs/toolkit/query/react";
import {baseWidgetQuery} from "@/store/features/widget/baseWidgetQuery";
import {User} from "@/services/models/user";
import {Chat} from "@/services/models/chat";
import {chatUISliceActions} from "@/store/features/chat/chatUiSlice";
import apiClient from "@/services/api/apiClient";
import {chatApi} from "@/store/features/chat/chatApiSlice";

export const widgetApi = createApi({
    reducerPath: 'widgetApi',
    baseQuery: baseWidgetQuery,
    endpoints: (builder) => ({
        getSession: builder.query<Chat, void>({
            query: () => ({
                url: '/Embed/widget/session',
                method: 'GET',
            }),
            async onQueryStarted(_, {queryFulfilled, dispatch}) {
                try {
                    const {data} = await queryFulfilled;

                    if (data) {
                        dispatch(chatUISliceActions.setChat({
                            chat: data,
                            project: null
                        }));

                        if (data.collection_id) {
                            window.history.pushState(null, '', `/widget/${data.collection_id}`);
                            dispatch(chatApi.util.invalidateTags(['History']));
                        }
                    }
                } catch (error) {
                    console.error('Error in getSession query:', error);
                }
            }
        }),
        createChat: builder.mutation<Chat, void>({
            query: () => ({
                url: `Embed/widget/chat/new`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            }),
        }),
        postNewMessage: builder.mutation<string, { chatId: string, agent: string, message: string }>({
            queryFn: async ({message, chatId, agent}, {endpoint, dispatch, signal}) => {

                try {
                    dispatch(chatUISliceActions.addUserMessages(message));
                    await apiClient.logChunksAndReturnFullResponse(
                        chatId,
                        message,
                        agent,
                        {
                            onChunk: chunk => dispatch(chatUISliceActions.addMessageChunk(chunk.message)),
                            onFinished: str => dispatch(chatUISliceActions.finishAiMessage()),
                        },
                        signal,
                        true
                    );
                    return {data: ""};
                } catch (error) {
                    console.log("Catched error on  postNewMessage", error);
                    dispatch(chatUISliceActions.resetAiMessageError());
                    return {
                        error: {
                            status: 'CUSTOM_ERROR',
                            data: error instanceof Error ? error.message : 'An unknown error occurred',
                            error: 'API Error'
                        }
                    };
                }
            }
        })
    }),
});

export const {useGetSessionQuery, usePostNewMessageMutation, useCreateChatMutation} = widgetApi;