import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQueryWithReAuth} from "@/store/features/baseRtkQuery";
import {ProjectTheme} from "@/services/models/project-theme";
import {themeSliceActions} from "@/store/features/theme/themeSlice";


export const themeApiSlice = createApi({
    reducerPath: 'themeApi',
    baseQuery: baseQueryWithReAuth,
    tagTypes: ['Theme'],
    endpoints: (builder) => ({
        getAllThemes: builder.query<ProjectTheme[], void>({
            query: () => ({
                url: `Themes`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }),
            transformResponse: (response: ProjectTheme[]) =>
                response.sort((a, b) => a.display_name.localeCompare(b.display_name)),

        }),
        getThemeById: builder.query<ProjectTheme, string>({
            query: (projectId) => ({
                url: `Themes/${projectId}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }),
            onQueryStarted: async (id, {queryFulfilled, dispatch}) => {
                try {
                    const {data} = await queryFulfilled
                    dispatch(themeSliceActions.setCurrentTheme(data));
                    // `onSuccess` side-effect
                    // dispatch(messageCreated('Posts received!'))
                } catch (err) {
                    // `onError` side-effect
                    // dispatch(messageCreated('Error fetching posts!'))
                }
            }
        })
    }),
})

const {useGetAllThemesQuery, useGetThemeByIdQuery} = themeApiSlice
export const themeApiActions = {
    useGetAllThemesQuery,
    useGetThemeByIdQuery
}