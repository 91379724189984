"use client";
import React, {useRef} from 'react';
import {Provider} from "react-redux";
import {makeStore, AppStore} from "@/store/store";

interface Props {
    readonly children: React.ReactNode;
}

export default function StoreProvider({
                                          children,
                                      }: Props) {
    const storeRef = useRef<AppStore>(null);

    if (!storeRef.current) {
        storeRef.current = makeStore();
    }
    return (
        <Provider store={storeRef.current}>
            {children}
        </Provider>
    );
}