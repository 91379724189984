import {createApi} from '@reduxjs/toolkit/query/react'
import {baseQueryWithReAuth} from "@/store/features/baseRtkQuery";
import {Project} from "@/services/models/project";
import {ProjectCreationResponse} from "@/services/models/projectCreationResponse";


export const projectApiSlice = createApi({
    reducerPath: 'projectApi',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getProjectAgent: builder.query<Agent[], { projectId: string }>({
            query: ({projectId}) => ({
                url: `Project/${projectId}/agents`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }),
        }),
        getUserProject: builder.query<Project[], void>({
            query: () => ({
                url: `Project`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }),
        }),
        postNewProject: builder.mutation<ProjectCreationResponse[], {
            name: string;
            description: string,
            theme_id: string
        }>({
            query: ({name, description, theme_id}) => ({
                url: `Project`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: {
                    name,
                    description,
                    theme_id
                },
            }),
        }),
    }),
})

const {
    useGetProjectAgentQuery,
    useGetUserProjectQuery,
    usePostNewProjectMutation
} = projectApiSlice
export const projectApiActions = {
    useGetProjectAgentQuery,
    useGetUserProjectQuery,
    usePostNewProjectMutation
}