import { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { baseQueryWithReAuth } from "@/store/features/baseRtkQuery";
import { getSession } from 'next-auth/react';
import { API_CONFIG } from '@/constants/apiConstants';
import { auth } from "@/auth";

export interface UploadQueryArgs {
    url: string;
    method: string;
    file?: File;
    onProgress?: (progress: number) => void;
}

export type UploadBaseQueryResult = {
    data?: any;
    error?: {
        status: number;
        data: string;
    };
};

export const uploadBaseQuery: BaseQueryFn<
    UploadQueryArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const { url, method, file, onProgress } = args;

    if (!file) {
        return baseQueryWithReAuth({
            ...args
        }, api, extraOptions);
    }

    const session = await getSession();
    const formData = new FormData();
    formData.append('file', file);

    return new Promise<UploadBaseQueryResult>((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable && onProgress) {
                const progress = Math.round((event.loaded / event.total) * 100);
                onProgress(progress);
            }
        };

        xhr.onload = async () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                try {
                    const data = JSON.parse(xhr.responseText);
                    resolve({ data });
                } catch {
                    resolve({ data: xhr.responseText });
                }
            } else if (xhr.status === 401) {
                const refreshResult = await auth();
                if (refreshResult) {
                    reject({
                        status: 401,
                        data: 'Auth refresh needed'
                    });
                } else {
                    reject({
                        status: 401,
                        data: 'Authentication failed'
                    });
                }
            } else {
                reject({
                    status: xhr.status,
                    data: xhr.statusText
                });
            }
        };

        xhr.onerror = () => {
            reject({
                status: xhr.status || 500,
                data: xhr.statusText || 'Network error occurred'
            });
        };

        const fullUrl = `${API_CONFIG.BASE_URL.API_URL}${url}`;
        xhr.open('POST', fullUrl);

        if (session?.id_token) {
            xhr.setRequestHeader('Authorization', `Bearer ${session.id_token}`);
        }

        xhr.send(formData);
    });
};