import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface FileUploading {
    id: string;
    name: string;
    progress: number;
    status: 'uploading' | 'completed' | 'error';
    error?: string;
}

interface UploadingFilesState {
    files: FileUploading[];
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
}

const initialState: UploadingFilesState = {
    files: [],
    status: 'idle',
    error: null,
};

export const uploadingFileSlice = createSlice({
    name: "uploadingFiles",
    initialState,
    reducers: (create) => ({
        setUploadStatus: create.reducer(
            (state, action: PayloadAction<'idle' | 'loading' | 'failed'>) => {
                state.status = action.payload;
            }
        ),
        setError: create.reducer(
            (state, action: PayloadAction<string | null>) => {
                state.error = action.payload;
            }
        ),
        addFile: create.reducer(
            (state, action: PayloadAction<FileUploading>) => {
                state.files.push(action.payload);
            }
        ),
        updateFileProgress: create.reducer(
            (state, action: PayloadAction<{ id: string; progress: number }>) => {
                const file = state.files.find(f => f.id === action.payload.id);
                if (file) {
                    file.progress = action.payload.progress;
                }
            }
        ),
        updateFileStatus: create.reducer(
            (state, action: PayloadAction<{
                id: string;
                status: FileUploading['status'];
                error?: string;
            }>) => {
                const file = state.files.find(f => f.id === action.payload.id);
                if (file) {
                    file.status = action.payload.status;
                    file.error = action.payload.error;
                }
            }
        ),
        clearFiles: create.reducer((state) => {
            state.files = [];
        }),
    })
});
export const uploadStatusActions = uploadingFileSlice.actions;


export default uploadingFileSlice;