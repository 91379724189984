import {configureStore} from "@reduxjs/toolkit";
import {chatUISlice} from "@/store/features/chat/chatUiSlice";
import UploadingFileSlice from "@/store/features/file/UploadingFileSlice";
import pendingMessageSlice from "@/store/features/chat/pendingMessageSlice";
import {themeSlice} from "@/store/features/theme/themeSlice";
import {chatApi} from "@/store/features/chat/chatApiSlice";
import {projectApiSlice} from "@/store/features/project/projectApiSlice";
import {themeApiSlice} from "@/store/features/theme/themeApiSlice";
import {projectFiles} from "@/store/features/file/fileApiSlice";
import {widgetApi} from "@/store/features/widget/widgetApiSlice";

export const makeStore = () => {
    return configureStore({
        devTools: {
            trace: true,
        },
        reducer: {
            chatUI: chatUISlice.reducer,
            uploadingFiles: UploadingFileSlice.reducer,
            pendingMessage: pendingMessageSlice.reducer,
            theme: themeSlice.reducer,
            [chatApi.reducerPath]: chatApi.reducer,
            [projectApiSlice.reducerPath]: projectApiSlice.reducer,
            [themeApiSlice.reducerPath]: themeApiSlice.reducer,
            [projectFiles.reducerPath]: projectFiles.reducer,
            widgetApi: widgetApi.reducer,
        },
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware()
                .concat(chatApi.middleware)
                .concat(projectFiles.middleware)
                .concat(projectApiSlice.middleware)
                .concat(themeApiSlice.middleware)
                .concat(widgetApi.middleware)
    });
};

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];